import { navigate } from "gatsby"
import React from "react"
import Layout from "../components/core/Layout"
import SEO from "../components/core/SEO"
import Images from "../components/Images"
import ProjectCard from "../components/ProjectCard"

const ProjectPage: React.FC = () => (
  <Layout activePage="projects">
    <SEO title="Projects" />
    {/* <h2 className="page-title">Current Projects</h2>
    <div className="divider divider--spacer-bottom" /> */}

    <h2 className="page-title">Completed Projects</h2>
    <div className="divider divider--spacer-bottom" />

    <ProjectCard style={{ cursor: "auto" }}>
      {/* onClick={() => navigate("/projects/pandemics-in-ceramics")} */}
      <ProjectCard.Content>
        <ProjectCard.Title>Pandemics in Ceramics</ProjectCard.Title>
        <p>
          Pandemics in Ceramics was a project that aimed to connect people with
          archaeological research into the impact of the Black Death by making
          medieval-style tiles commemorating the experiences of COVID-19. The
          project was undertaken as part of the Being Human festival of
          humanities in association with the University of Lincoln.{" "}
          {/* <a href="#">Click here</a> to learn more. */}
          {/* <span className="timescale">March 2021 - May 2021</span> */}
        </p>
      </ProjectCard.Content>
      <Images image="pandemicsinceramics" />
    </ProjectCard>

    <ProjectCard onClick={() => navigate("/projects/2-gether")}>
      <ProjectCard.Content>
        <ProjectCard.Title>2-gether</ProjectCard.Title>
        <p>
          2-gether was an Arts council and National Lottery funded project that
          was aimed at people who required support from a carer or loved one and
          have been isolated by COVID-19 restrictions. The participants
          collaborated with a significant other to create three-dimensional
          tiles that were collated to form a large mural.{" "}
          <a href="#">Click here</a> to learn more.
          <span className="timescale">April 2021 - June 2022</span>
        </p>
      </ProjectCard.Content>
      <Images image="2gether" />
    </ProjectCard>
    <ProjectCard style={{ cursor: "auto" }}>
      {/* onClick={() => navigate("/projects/pandemics-in-ceramics")} */}
      <ProjectCard.Content>
        <ProjectCard.Title>
          Tonic Health Spalding - Tonic Tasters
        </ProjectCard.Title>
        <p>
          The NHS Managed Care Network and Transported Art funded a project to
          work with dementia and mental health groups run by Tonic Health. This
          involved working with the groups to teach them different techniques
          and skills. The final aim of the project was to put on an exhibition
          showing all of the work that they created.
          {/* <a href="#">Click here</a> to learn more. */}
          {/* <span className="timescale">March 2021 - May 2021</span> */}
        </p>
      </ProjectCard.Content>
      <Images image="tonictasters" />
    </ProjectCard>

    <ProjectCard onClick={() => navigate("/projects/community-clay")}>
      <ProjectCard.Content>
        <ProjectCard.Title>Community Clay</ProjectCard.Title>
        <p>
          Community Clay was a project undertaken with Transported as part of
          their Creative Response to Loneliness programme. We sent out kits to
          make personalised ceramic tiles. No previous experience working with
          clay was needed. <a href="#">Click here</a> to learn more.
          <span className="timescale">March 2021 - May 2021</span>
        </p>
      </ProjectCard.Content>
      <Images image="communityclay" />
    </ProjectCard>
    <ProjectCard onClick={() => navigate("/projects/scott-house-cc")}>
      <ProjectCard.Content>
        <ProjectCard.Title>Scott House (Community Clay)</ProjectCard.Title>
        <p>
          This was an extension of the Community Clay project, which was jointly
          funded by Transported and Scott House. All of the clay tiles made were
          made by people that attend Scott House.
          <span className="timescale">March 2021 - October 2021</span>
        </p>
      </ProjectCard.Content>
      <Images image="scotthouse" />
    </ProjectCard>
    <ProjectCard onClick={() => navigate("/projects/boston-buoys")}>
      <ProjectCard.Content>
        <ProjectCard.Title>Boston Buoys</ProjectCard.Title>
        <p>
          We were commissioned to run a project working with schoolchildren in
          the local area to produce clay fish, which would become part of a
          mosaic. This project was part of the wider Boston Buoys project.
          <span className="timescale">March 2021 - July 2021</span>
        </p>
      </ProjectCard.Content>
      <Images image="bostonbuoysfish" />
    </ProjectCard>
  </Layout>
)

export default ProjectPage
