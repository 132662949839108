import React, { HTMLProps } from "react"

const ProjectCardContent: React.FC<HTMLProps<HTMLDivElement>> = props => (
  <div className="project-card__content" {...props} />
)

const ProjectCardTitle: React.FC<HTMLProps<HTMLHeadingElement>> = props => (
  <h2 className="project-card__title" {...props} />
)

interface ProjectCard extends React.FC<HTMLProps<HTMLDivElement>> {
  Content: typeof ProjectCardContent
  Title: typeof ProjectCardTitle
}

const ProjectCard: ProjectCard = props => {
  return (
    <div
      className="project-card"
      {...props}
      onKeyPress={e => {
        if (e.key === "Enter") (e.target as HTMLDivElement).click()
      }}
    ></div>
  )
}

ProjectCard.defaultProps = { role: "button", tabIndex: 0 }

ProjectCard.Content = ProjectCardContent
ProjectCard.Title = ProjectCardTitle

export default ProjectCard
